<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/landpurchase`" title="Land Purchase"
                :columns="columns" routerpath="/accounting/addlandpurchase" :formOptions="formOptions" :edit="edit"
                :add="add" :canDelete="canDelete" viewComponent="LandPurchase" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/components/axios";
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
import moment from "moment";
const baseApi = process.env.VUE_APP_APIENDPOINT;

export default {
  components: {
    GoodTableColumnSearch,
  },
  data() {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Land Purchase" : "Add Land Purchase"
          }`,
        submitRouterPath: "/crm/landpurchase",
        usersDropdown: true,
        method: "post",
        action: "add",
        url: `${baseApi}/landpurchase`,
        inputFields: [
          {
            name: "branch",
            value: 1,
            type: "hidden",
          },
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          // {
          //   name: "nextfollowupdate",
          //   value: `${moment(new Date()).format("DD/MM/yyyy")}`,
          //   type: "hidden",
          // },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Voucher NO",
            name: "voucherno",
            value: "",
            type: "number",
            placeholder: "Enter Voucher Number",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Date",
            name: "date",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select date",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Cheque NO",
            name: "chequeno",
            value: "",
            type: "number",
            placeholder: "Enter Cheque Number",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Client Name",
            name: "clientname",
            value: "",
            type: "text",
            placeholder: "Enter Client Name",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Company Name",
            name: "companyname",
            value: "",
            type: "dropdown",
            class: "col-md-6",
            dropdownname: "companyname",
            options: [],
            // required: {
            //   required,
            // },
          },
          {
            label: "Bank Name",
            name: "bankname",
            value: "",
            type: "text",
            placeholder: "Enter Bank Name",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Amount",
            name: "amount",
            value: "",
            type: "text",
            placeholder: "Enter Amount",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Cheque Date",
            name: "chequedate",
            value: moment(new Date()).format("yyyy-MM-DD"),
            type: "date",
            placeholder: "Select Cheque date",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Reason",
            name: "reason",
            value: "",
            type: "text",
            placeholder: "Enter Reason",
            class: "col-md-6",
            // required: {
            //   required,
            // },
          },
          {
            label: "Client's Bank",
            name: "clientbank",
            value: "",
            type: "text",
            placeholder: "Enter Client's Bank",
            class: "col-md-6",
          },
        ],
      },
      columns: [

        {
          label: "Village Name",
          field: "villagename",
          url: "allLand",
          type: "dropdown",
          responseValue: "villagename",
          dropdownname: "villagename",
          filterOptions: {
            enabled: true,
            placeholder: "Search Village Name",
          },
        },
        {
          label: "Survey No. Old",
          field: "surveynoold",
          filterOptions: {
            enabled: true,
            placeholder: "Search Survey No. Old",
          },
        },
        {
          label: "Survey No. New",
          field: "surveynonew",
          filterOptions: {
            enabled: true,
            placeholder: "Search Survey No. New",
          },
        },
        {
          label: "Original Plot No. (OP)",
          field: "orignalplotno",
          filterOptions: {
            enabled: true,
            placeholder: "Search Original Plot No. (OP)",
          },
        },
        {
          label: "FP No",
          field: "fpno",
          filterOptions: {
            enabled: true,
            placeholder: "Search FP No",
          },
        },
        {
          label: "Khata No",
          field: "khatano",
          filterOptions: {
            enabled: true,
            placeholder: "Search Khata No",
          },
        },

        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
    };
  },

  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Land Purchase") {
          console.log(item);
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;
          if (item.edit !== 1 && item.add !== 1 && item.delete !== 1 && item.view !== 1) {
            this.$router.push("/");
          }
        }
      });
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
